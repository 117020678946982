import React from "react";
import { Link } from "react-router-dom";

const FrontPageGrid = () => {
    const gridItems = [

        {
            href: "https://muulti.xyz/",
            title: "Muulti - Token Gated Media Playlists",
            id: "muulti-item",
            titleId: "muulti-title",
        },
        {
            href: "https://bunnyrunner.weti.co/",
            title: "Bunny Runner",
            id: "bunny-runner-item",
        },

        {
            href: "https://exchange.art/simulations/nfts",
            title: "Simulations Exchange Art",
            id: "",
        },
        {
            href: "https://www.mallow.art/",
            title: "Simulations Mallow Art",
            id: "mallow-title-item",
            titleId: "mallow-title",
        },
        {
            href: "https://magiceden.io/marketplace/microwave",
            title: "The Meta Microwaves",
            id: "meta-microwave-item",
        },
    ];

    const linkItems = [
        {
            to: "/gm-blockchain",
            title: "GM Blockchain",
            id: "gm-blockchain-item",
        },
        {
            to: "/mimesis-castle",
            title: "Mimesis Castle on DRiP",
            id: "mimesis-castle-item",
        },
    ];

    return (
        <div>
            <h1 className="poppins-title-italic">Projects</h1>
            <p>Projects and collaborations that we produced the web3 space.</p>
            <div className="front-page-grid-container">
                {gridItems.map((item, index) => (
                    <a
                        key={index}
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="front-page-grid-item" id={item.id || ""}>
                            <div className="front-page-item-title" id={item.titleId || ""}>
                                <h1>{item.title}</h1>
                            </div>
                        </div>
                    </a>
                ))}

                {linkItems.map((item, index) => (
                    <Link key={index} to={item.to}>
                        <div className="front-page-grid-item" id={item.id || ""}>
                            <div className="front-page-item-title">
                                <h1 className="front-page-title">{item.title}</h1>
                                {item.description && <p id={`${item.id}-description`}>{item.description}</p>}
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default FrontPageGrid;
